import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const section_1 = require("../../../assets/img/blogs/vir_blog_agnt1.png");
const section_2 = require("../../../assets/img/blogs/vir_blog_agnt2.png");
const section_3 = require("../../../assets/img/blogs/vir_blog_agnt3.png");
const section_4 = require("../../../assets/img/blogs/vir_blog_agnt4.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Ultimate guide to Virtual Agent"
        description="In this guide, we'll be covering what a virtual agent & A Detailed Overview of How They Work is & some examples of virtual agents "
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={section_1}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header">
                            Everything You Need To Know About Virtual Agent
                          </h1>
                        </div>
                      </div>

                      <div>
                        <div className="w-100 float-left">
                          <h2 className="font-section-sub-header-small-bold">
                            Introduction
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/conversational-ai-platform/virtual-agent">
                              Virtual agents
                            </a>{" "}
                            are a platform to be fed with massive datasets to
                            mimic human interactions and offer a personalized
                            response to solve a query.
                            <a href="https://workativ.com/conversational-ai-platform/blog/employee-self-service-chatgpt">
                              Hyper-personalized solutions
                            </a>{" "}
                            deliver real outcomes for the rising business
                            complexities and customer expectations. Virtual
                            agents fit the immediate demand of businesses, given
                            the ever-increasing landscape of massive attrition,
                            GenZ, or millennials being impatient and seeking an
                            instant response to their queries.
                          </p>

                          <p class="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/conversational-ai-platform">
                              Automated responses or services that AI virtual
                              agents offer
                            </a>{" "}
                            thus better complement processes across customer
                            service or employee support. In this view, virtual
                            agents are digital assistants that empower users to
                            be able to solve issues with well-defined guidance
                            and support in a more humanized manner.
                          </p>

                          <p class="font-section-normal-text line-height-2">
                            However, we often confuse virtual agents with
                            chatbots or virtual assistants. But, they differ in
                            capabilities and technologies used at the core.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Below, we will uncover significant facts about
                            virtual agents, their benefits, use cases, and more.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Keep reading.
                          </p>
                        </div>

                        {/* Section 1 */}
                        <div className="w-100 float-left" id="section1">
                          <h3 className="font-section-sub-header-small-bold">
                            What Is A Virtual Agent?
                          </h3>
                          <img
                            src={section_1}
                            alt={"what is a virtual agent"}
                          />
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-testimonials-medium">
                              {" "}
                              A virtual agent is an artificial intelligence (AI)
                              software program{" "}
                            </span>
                            that can mimic human interactions. A virtual Agent
                            is an entity that exists only in a digital or
                            virtual world. It interacts with users through text
                            or voice chat, and its purpose is to provide
                            information or assistance. Sometimes,{" "}
                            <a href="https://workativ.com/conversational-ai-platform">
                              Virtual Agents are also called chatbots or
                              conversational agents.
                            </a>
                          </p>

                          <p class="font-section-normal-text line-height-2">
                            Virtual agents are designed to simulate natural
                            human dialogue and can perform various tasks, such
                            as answering customer questions, making
                            recommendations, or providing information.
                          </p>
                        </div>
                        <NocodeWrapper />

                        {/* Section 2 */}
                        <div className="w-100 float-left" id="section2">
                          <h2 className="font-section-sub-header-small-bold">
                            How Virtual Agent Works?
                          </h2>

                          <p className="font-section-normal-text line-height-2">
                            Virtual agents use artificial intelligence (AI)
                            technologies, such as natural language processing
                            (NLP) and natural language understanding (NLU),
                            <span className="font-section-normal-text-testimonials-medium">
                              {" "}
                              to interpret the intent of users’ queries and
                              generate responses.
                            </span>
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            Based on the existing workflows that come up every
                            day in the customer handling service or employee
                            support, businesses can prepare dialog and ingest
                            those scripted datasets to the AI data model and
                            train it to respond to queries effectively.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            Over time, the{" "}
                            <a href="https://workativ.com/conversational-ai-platform/chatbot-automation">
                              automated responses
                            </a>{" "}
                            also improve interaction and accelerate process
                            efficiency as intelligent virtual agents gain the
                            ability to learn across the complexities users face
                            and offer solutions for less predictable inquiries
                            by analyzing the chat history.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            For example, a virtual agent or conversational AI
                            agent does not repeat the scripted template again
                            and again once the conversation exhausts the
                            existing options. Instead, it uses intelligence and
                            offers real-time suggestions. When those do not work
                            out, it can help with agent handover.
                          </p>
                        </div>

                        {/* Section 3 */}
                        <div className="w-100 float-left" id="section3">
                          <h2 className="font-section-sub-header-small-bold">
                            Virtual Agent v/s Chatbot v/s Virtual Assistant
                          </h2>

                          <p className="font-section-normal-text line-height-2">
                            We often hear the terms virtual agent, chatbot, and
                            virtual assistant used interchangeably, but there
                            are some distinct differences between these three
                            platforms. Let’s take a closer look at each one.
                          </p>
                          <h3 className="font-section-sub-header-small">
                            Virtual agents{" "}
                          </h3>
                          <p className="font-section-normal-text line-height-2">
                            A virtual agent is an artificial intelligence
                            (AI)-powered customer service representative.
                            Virtual agents are designed to simulate a human
                            conversation and can understand and respond to
                            natural language queries. Or more specifically,
                            intelligent virtual agents feed on conversational AI
                            capabilities that can parse human dialog and
                            improves dialog exchange between a human and
                            machine.
                          </p>
                          <h3 className="font-section-sub-header-small">
                            Chatbots
                          </h3>
                          <p className="font-section-normal-text line-height-2">
                            A chatbot is a type of virtual agent built on top of
                            <a href="https://workativ.com/conversational-ai-platform/chatbot-automation">
                              robotic process automation
                            </a>{" "}
                            to offer suggestions and solutions powered by
                            pre-determined dialog and workflows for common
                            queries. Chatbots use{" "}
                            <a href="https://workativ.com/conversational-ai-platform/faq-chatbot-builder">
                              FAQ-based resources
                            </a>{" "}
                            to respond to users.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            Note: In many instances, robotic process automation
                            gets exhausted and fails to meet business
                            expectations regarding hyper-automation needs.
                            Natural Language Processing provides advanced intent
                            detection and entity extraction capabilities to
                            overcome the limitations of FAQ-based chatbots and
                            translate them into conversational AI platforms that
                            can act as intelligent virtual agents. By using
                            conversational ai with app workflow automation, you
                            can create intelligent chatbots.
                          </p>
                          <h3 className="font-section-sub-header-small">
                            Virtual assistants
                          </h3>
                          <p className="font-section-normal-text line-height-2">
                            A virtual assistant is a virtual agent designed to
                            perform tasks or services on behalf of a user.
                            Virtual assistants can range from simple
                            voice-activated assistants, like Amazon’s Alexa, to
                            more complex agents that can handle tasks such as
                            scheduling appointments and managing email.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            However, virtual assistants also represent human
                            agents who work virtually for a business to assist
                            with customer or user queries. For say, contact
                            centers or call center agents are known as virtual
                            assistants, who use technology to solve customer
                            issues.
                          </p>
                        </div>

                        {/* Section 4 */}
                        <div className="w-100 float-left" id="section4">
                          <h2 className="font-section-sub-header-small-bold">
                            Examples of Virtual agent
                          </h2>
                          <h3 className="font-section-sub-header-small">
                            Virtual Agent Chatbot - Customer Support
                          </h3>

                          <p className="font-section-normal-text line-height-2">
                            Imagine customer support in an insurance company.
                            The contact center receives hundreds of queries, if
                            not thousands, daily. Some seek an instant answer
                            for the claim procedure. At the same time, some may
                            need help with information that could give an
                            in-depth understanding of various health-related
                            benefits they can get with their specific policies.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            The insurance company may visualize the interaction
                            capacity that can happen, and thus they create
                            automated responses for the{" "}
                            <a href="https://workativ.com/conversational-ai-platform/virtual-agent">
                              virtual agent platform.
                            </a>
                            So, whenever a customer initiates a conversation,
                            the virtual agent can ask for a policy number and
                            phone number, correlating the data with internal CRM
                            to offer a response. Furthermore, more advanced
                            virtual agents can even help users create tickets
                            instantly if the automated response does not satisfy
                            their questions.
                          </p>

                          <h3 className="font-section-sub-header-small">
                            Virtual Agent Chatbot - Employee Support
                          </h3>
                          <p className="font-section-normal-text line-height-2">
                            Harvard Business Review{" "}
                            <a href="https://hbr.org/2023/02/survey-employees-want-business-technologies-to-be-more-collaborative">
                              survey predicts that employees want collaboration
                              tools aligned with their internal processes.
                            </a>{" "}
                            This is essentially important for the remote
                            workforce who need the information to work
                            efficiently. The survey reports that disparate
                            software spread across departments creates more
                            fragmentation and silos.{" "}
                            <a href="https://workativ.com/conversational-ai-platform">
                              Virtual agent chatbot that allows app integration
                              and workflow automation helps&nbsp;
                            </a>
                            employee harness information steadily.
                          </p>
                          <img
                            src={section_2}
                            alt="virtual agents to help with marketing activities"
                          />
                          <p className="font-section-normal-text-medium">
                            Say your marketing executive is working virtually
                            and looking for customer information to send a
                            follow-up message. But, he does not have access to
                            the CRM platform. The point of contact (i.e., the
                            marketing head) is gone on a business trip. As
                            usual, there would ensue a delay of several days or
                            weeks.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            But, a virtual agent chatbot can quickly reduce the
                            risk of losing a prospect.
                            <a href="https://workativ.com/conversational-ai-platform/hubspot-crm-chatbot">
                              {" "}
                              With automated app workflows built for a CRM
                              platform and integrated inside your favorite
                              communication tools like Teams or Slack,
                            </a>{" "}
                            your marketing executive can easily find customer
                            information and proceed accordingly.
                          </p>

                          <h4 className="font-section-sub-header-small">
                            Virtual Agent Chatbot - IT helpdesk
                          </h4>
                          <p className="font-section-normal-text line-height-2">
                            Implementing a Virtual Agent Chatbot within an IT
                            support organization can help human agents in
                            several ways.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            First, it can help deflect routine support
                            inquiries, allowing agents to focus on more complex
                            issues.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            IT help desk has routine inquiries for system
                            malfunctioning, credential issues, and more, such as
                          </p>
                          <ul className="font-section-normal-text float-left w-100">
                            <a href="https://workativ.com/use-cases/reset-password-automation">
                              <li>Password resets</li>
                            </a>
                            <li>Printer issues</li>
                            <li>Device provisioning</li>
                          </ul>
                          <p className="font-section-normal-text line-height-2">
                            More such routine issues add to poor employee
                            experience.
                          </p>
                          <img
                            src={section_3}
                            alt="password reset automation for a virtual agent chatbot"
                          />
                          <p className="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                              A virtual agent chatbot can be programmed to help
                              users with knowledgebase articles or FAQ-based
                              feeds&nbsp;
                            </a>
                            to solve the most mundane and error-prone
                            activities.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            So, with a virtual agent chatbot, you can automate
                            several repetitive tasks and increase employee
                            productivity and satisfaction.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            {" "}
                            <a
                              href="https://workativ.com/conversational-ai-platform/virtual-agent-powered-it-helpdesk"
                              target="_blank"
                            >
                              Read more.
                            </a>
                          </p>
                        </div>

                        {/* Section 5 */}
                        <div className="w-100 float-left" id="section5">
                          <h2 className="font-section-sub-header-small-bold">
                            Benefits of Virtual Agents- Role of Virtual Agents
                            in your business
                          </h2>

                          <p className="font-section-normal-text line-height-2">
                            You must have an excellent team for your customer
                            service, like one of the superheroes. Your team
                            handles various aspects like emails, online chats,
                            social messages, and direct phone calls; however,
                            virtual agents can add to elite customer service.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            A collaboration between a customer service team and
                            a virtual agent lowers the workload of your
                            hard-working team and improves SLAs.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            We’ve listed down why it's necessary to onboard a
                            virtual agent and some best practices.
                          </p>
                          <h3 className="font-section-sub-header-small">
                            1. Cost savings:
                          </h3>
                          <p className="font-section-normal-text line-height-2">
                            You can reduce ticket handling costs and save big on
                            operational expenses.
                          </p>
                          <ul className="w-100 float-left font-section-normal-text">
                            {" "}
                            <li>
                              Each ticket for L1 support costs $20, which you
                              can reduce to less than $2 by using{" "}
                              <a href="https://workativ.com/conversational-ai-platform/ticket-deflection">
                                chatbot automation for the IT help desk.
                              </a>
                            </li>
                            <li>
                              Free up 30% of agent capacity, thus reducing
                              dependencies on agents and additional costs for
                              agent salary and incentives and insurance.{" "}
                            </li>
                            <li>
                              No-code platforms can provide the flexibility to
                              use hyper-automation by leveraging NLP and
                              generative AI for automated chatbots or virtual
                              agents. So you can reduce in-house development
                              costs.
                            </li>
                            <li>
                              Virtual chatbots are cloud-native and available as
                              a SaaS model from anywhere without investing in
                              costly infrastructure maintenance and licensing
                              fees.
                            </li>
                          </ul>
                          <p className="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/">Workativ</a>{" "}
                            automates workplaces and reduces costs for building
                            expensive and developer-intensive chatbots, app
                            integrations, and workflows.
                          </p>
                          <h3 className="font-section-sub-header-small">
                            2. Improved customer satisfaction:
                          </h3>

                          <p className="font-section-normal-text line-height-2">
                            One thing is always at the top of the mind of every
                            customer, no matter if you’re running a Forbes 500
                            company or a local online store. Customers will
                            always seek out positive customer experiences.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            And one of the concrete ways of measuring customer
                            satisfaction is to count on your response time and
                            the speed of your services. It is essential to
                            understand what you’re losing and what you’re
                            gaining while providing the best customer service.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            Virtual agents can provide high customer
                            satisfaction, as they are available 24/7 and often
                            provide faster resolutions to customer issues.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            When customers get responses in real-time using
                            virtual agents, it meets better service level
                            agreements. Timely resolution of issues doesn't get
                            bigger and leads to customer satisfaction.
                          </p>
                          <h3 className="font-section-sub-header-small">
                            3. Automates repetitive tasks-
                          </h3>

                          <p className="font-section-normal-text line-height-2">
                            Virtual agents automate  your employee  IT issues
                            and service requests with an intelligent virtual
                            agent Bot combined with workflow automation. Virtual
                            agents can quickly scale up or down to meet changing
                            customer demands. They take care of the repetitive
                            queries that make humans feel like machines,
                            allowing live agents to focus on priority work.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            For example, HR can streamline communication and
                            reduce manual intervention to respond to each query
                            regarding policies and procedures ( exit, onboard,
                            BYOD, health insurance, car loans, clearance,
                            bookings, participation queries, donation drives,
                            recruitment drives, and more). With consistent
                            forms, answers, and automated solutions, an HR can
                            focus on more important things than those
                            unproductive daily tasks.
                          </p>
                          <h3 className="font-section-sub-header-small">
                            4. Lead Generation
                          </h3>
                          <p className="font-section-normal-text line-height-2">
                            If you’re a small startup owner, it is possible that
                            your primary goal is to increase lead generation. In
                            that case, virtual agents function efficiently
                            through outbound telemarketing resulting in multiple
                            leads. They take the sales rep's work and push it
                            forward by following up and analyzing data to
                            identify prospective customers.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            For example: while you’re busy doing errands for
                            your new business, a virtual agent can collect data
                            like name, phone number, city, email address, and
                            what you are looking for– some similar sections and
                            feed into a database and later using software/tools
                            you can design a cold email pitch for your customers
                            and sell your services directly in their inbox.
                          </p>
                          <h3 className="font-section-sub-header-small">
                            5. Omni - channel support
                          </h3>

                          <p className="font-section-normal-text line-height-2">
                            Be there where your customers are.{" "}
                            <a href="https://workativ.com/conversational-ai-platform/chatbot-channels">
                              Omnichannel support
                            </a>{" "}
                            will not only drive your sales but, at the same
                            time, ensure better customer service.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            For an excellent support strategy, you must present
                            everywhere- you never know where your customer is
                            sitting– cribbing about service or hogging a quick
                            solution.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            A virtual agent can be configured to an omnichannel
                            support environment to ensure the escalated
                            conversation can be
                            <a href="https://learn.microsoft.com/en-us/training/modules/omnichannel-power-virtual-agents/4-configure-omnichannel">
                              &nbsp;routed to the correct queues.
                            </a>
                          </p>
                          <img
                            src={section_4}
                            alt="a virtual agent for Teams "
                          ></img>
                          <p className="font-section-normal-text line-height-2">
                            For example,
                            <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                              {" "}
                              Workativ virtual agents can easily sync with your
                              communication channels and increase user adoption
                            </a>{" "}
                            as they can interact directly via Slack or Teams.{" "}
                          </p>
                        </div>

                        {/* Section 6 */}
                        <div className="w-100 float-left" id="section6">
                          <h2 className="font-section-sub-header-small-bold">
                            Few more benefits to unlocking opportunities for
                            your business.
                          </h2>
                          <h3 className="font-section-normal-text-medium">
                            Call Centre
                          </h3>

                          <p className="font-section-normal-text line-height-2">
                            Virtual agents can work with the full-time
                            functionality of a regular call center. They can
                            also automatically pull data from databases to
                            provide instant solutions to customer inquiries.
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            Promotes Sales
                          </h3>
                          <p className="font-section-normal-text line-height-2">
                            Virtual agents can help sales teams close more deals
                            and boost productivity by automating repetitive
                            tasks and providing accurate and up-to-date product
                            information.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            Some of the top benefits of using virtual agents for
                            sales include the following:
                          </p>
                          <p className="font-section-normal-text mb-0 pb-0">
                            -Increased accuracy and up-to-date product
                            information
                          </p>
                          <p className="font-section-normal-text mb-0 pb-0">
                            -More deals closed
                          </p>
                          <p className="font-section-normal-text pb-0">
                            -Increased productivity
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            Provides 24/7 support and self-service
                          </h3>
                          <p className="font-section-normal-text line-height-2">
                            Virtual agents handle calls 24/7 and solve customer
                            cases quickly, so human contact is unnecessary.
                            Their engagement with customers helps in the
                            efficient provision of self-service as well.
                          </p>
                        </div>

                        <div className="w-100 float-left" id="section6">
                          <h2 className="font-section-sub-header-small-bold">
                            Build or Buy Virtual Agent - Things to consider
                            while choosing the virtual agent for your business.
                          </h2>

                          <p className="font-section-normal-text line-height-2">
                            According to a survey by MIT Technology Review, 90%
                            of businesses reported considerable improvements in
                            complaint resolution after using chatbots. That
                            brings us to our next element- Should you build a
                            virtual agent or buy one from companies that will
                            customize them to suit your requirements?
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            Here are a few parameters to check before deciding -
                          </p>
                          <ol>
                            <li className="font-section-normal-text">
                              <span className="font-section-normal-text-medium">
                                Project Vs Product:
                              </span>{" "}
                              Building a virtual agent has implications like
                              hiring a developer, a UI/UX person, testers, and a
                              product manager to ensure these are handled. But
                              if you buy the VA, you’ll have access to the
                              software immediately.
                            </li>
                            <li className="font-section-normal-text">
                              <span className="font-section-normal-text-medium">
                                Finding Skilled developers:
                              </span>{" "}
                              To build a Virtual Agent, you need a highly
                              skilled developer but isn’t that also a
                              challenging task?
                            </li>
                            <li className="font-section-normal-text">
                              <span className="font-section-normal-text-medium">
                                Cost / Affordability:
                              </span>{" "}
                              Clearly, buying a virtual agent and customizing it
                              to suit your requirement is cheaper than actually
                              building one from scratch.
                            </li>
                            <li className="font-section-normal-text">
                              <span className="font-section-normal-text-medium">
                                Ease of Deployment:
                              </span>{" "}
                              Deploying a virtual agent on your private hosting
                              platform is difficult, but it is easy as there is
                              zero downtime when it is a SaaS product.
                            </li>
                            <li className="font-section-normal-text">
                              <span className="font-section-normal-text-medium">
                                Ease of Maintenance:
                              </span>{" "}
                              In the case of virtual agents, maintenance also
                              implies maintaining the conversations. While
                              buying one, the responsibility lands in the SaaS
                              company’s hands, but if you build it, it is
                              constant work.
                            </li>
                            <li className="font-section-normal-text">
                              <span className="font-section-normal-text-medium">
                                Need for data for continuous training:
                              </span>{" "}
                              When you buy a Virtual Agent, the vendor regularly
                              updates it, but while building one, the commitment
                              lies on you.
                            </li>
                            <li className="font-section-normal-text">
                              <span className="font-section-normal-text-medium">
                                Time-to-market vs Time-to-value:
                              </span>{" "}
                              If you build a virtual agent, the time-to-market
                              will be at least a minimum of 6 months. Besides
                              losing on the time-to-value, the need to hire, the
                              costs involved, and the data required to train the
                              bot are just additional expenses.
                            </li>
                          </ol>
                        </div>

                        <div className="w-100 float-left" id="section6">
                          <h2 className="font-section-sub-header-small-bold">
                            How to build a Virtual Agent Chatbot in a no ode-
                            platform?
                          </h2>

                          <p className="font-section-normal-text line-height-2">
                            RPA tools offer UX support, but it tends to be
                            restrictive, so it needs to be more reliable.
                            Developers focus on programming to automate
                            processes as No Code solutions reduce the need to
                            code for RPA, increasing accessibility.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            Workativ Assistant is a SaaS no-code platform that
                            builds, automates, and deploys conversational AI
                            chatbots with workflow automation.
                            <a
                              href="https://workativ.com/conversational-ai-platform/steps-to-build-virtual-agent"
                              target="_blank"
                            >
                              {" "}
                              Watch this space where we create an intelligent
                              Virtual Assistant in less than 10 mins.
                            </a>
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            From chatbot builder to workflow builder to chatbot
                            integration, the video explains how virtual agents
                            solve IT issues, and one-click integration with ITSM
                            tools, along with downloading pre-built ITSM apps
                            and Virtual assistants and going live in minutes.
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            To learn more about how to build your virtual agents
                            with a no-code platform such as Workativ,{" "}
                            <a href="https://calendly.com/workativ/workativ-assistant-demo-page">
                              book a demo today.
                            </a>
                          </p>
                        </div>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/build-or-buy-virtual-agent">
                                Build or buy Virtual Agent
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/virtual-agent-powered-it-helpdesk">
                                How Virtual Agent can help IT Service Desks?
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-workplace-support-bot">
                                {" "}
                                MS Teams Bot - Your ultimate workplace support
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper margin-no-code">
      <h4>No Code - Free Virtual Agent</h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="conversational chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
